<template>
    <div>
        <h3>Admin Dashboard</h3>
        <hr>
        <admin-menu></admin-menu>
        <hr>
        <admin-control-panel></admin-control-panel>
    </div>
</template>

<script>
import AdminMenu from "@/components/AdminMenu";
import AdminControlPanel from "@/components/AdminModules/AdminControlPanel";
import roles from '@/roles';

export default {
    name: "AdminDash",
    components: {
        AdminMenu,
        AdminControlPanel
    },
    created() {
        if (!this.$store.state.idToken) {
            this.$router.push('/');
        }
        else if (this.$store.getters.role < roles.roles.technician) {
            this.$router.push('/dashboard');
        }
    }
}
</script>

<style scoped>

</style>
