<template>
    <div>
        <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Password Reset: {{curUser}} ?</h5>
            </div>
            <hr>
            <div>
                This will initiate the password reset process, the user will receive an
                email shortly with instruction on how to proceed.
            </div>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="resetYes">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="resetNo">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "ResetPassDialog",
    props: ['id'],
    data() {
        return {
            checkOpt: true
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.$refs['confirm-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods:{
        resetYes() {
            this.$refs['confirm-modal'].hide();
            this.$http.post('users/resetPassInit', {
                username: this.$store.getters.adminSelectedUser.username
            }).then(response => {
                return response.json();
                // eslint-disable-next-line no-unused-vars
            }).then(res => {
                this.$bvToast.toast('User Password Reset Initiated', {
                    title: 'Password Reset',
                    variant: 'success',
                    solid: true
                });
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                this.$bvToast.toast('Error Initiating Password Reset', {
                    title: 'Password Reset',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        resetNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
