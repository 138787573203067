<template>
    <div>
        <b-modal ref="confirm-rem-user-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Remove User From Device?</h5>
            </div>
            <hr>
            <div class="text-center">
                Removing the user from the device will delete all Device data files,
                essentially resetting the Device back to a new state.
            </div>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="deleteYes">Yes (No Undo)</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="deleteNo">No</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "RemDevUser",
    props: ['id'],
    data() {
        return {

        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.$refs['confirm-rem-user-modal'].show();
        });
    },
    beforeDestroy() {
      EventBus.$off(this.id);
    },
    methods:{
        deleteYes() {
            this.$refs['confirm-rem-user-modal'].hide();
            const devId = this.$store.getters.adminSelectedDev.deviceId;
            this.$store.dispatch('removeDeviceUser', devId).then(() => {
                this.$bvToast.toast('User Has Been Remove From Device', {
                    title: 'Remove User From Device',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminDevList');
            }).catch(() => {
                this.$bvToast.toast('Error Removing User From Device', {
                    title: 'Remove User From Device',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        deleteNo() {
            this.$refs['confirm-rem-user-modal'].hide();
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
