<template>
    <div>
        <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Delete Device: {{curDev}} ?</h5>
            </div>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="deleteYes">Yes (No Undo)</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="deleteNo">No</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "DeleteDevDialog",
    props: ['id'],
    data() {
        return {
        }
    },
    mounted() {
        EventBus.$on("Dev-Delete", () => {
            this.$refs['confirm-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off("Dev-Delete");
    },
    methods:{
        deleteYes() {
            this.$refs['confirm-modal'].hide();

            this.$store.dispatch('deleteDevice',
                this.$store.getters.adminSelectedDev.deviceId
            ).then(() => {
                this.$bvToast.toast('Device Has Been Deleted', {
                    title: 'Delete Device',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminDevList');
            }).catch(() => {
                this.$bvToast.toast('Error Deleting Device', {
                    title: 'Delete Device',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        deleteNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        curDev () {
            if (this.$store.getters.adminSelectedDev !== null)
                return this.$store.getters.adminSelectedDev.deviceId;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
