<template>
    <div>
        <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Change Username: {{curUser}} ?</h5>
            </div>
            <hr>
            <b-form-group>
                <label class="m-25" for="change-username">New Username:</label>
                <b-form-input
                    class="m-75"
                    id="change-username"
                    type="text"
                    v-model="form.newusername"
                    required
                ></b-form-input>
            </b-form-group>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="changeYes">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="changeNo">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "ChangeUsernameDialog",
    props: ['id'],
    data() {
        return {
            form: {
                name: null,
                newusername: null
            }
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.form.newusername = '';
            this.$refs['confirm-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods:{
        changeYes() {
            this.$refs['confirm-modal'].hide();

            this.$http.patch('users/changeUsername', {
                username: this.$store.getters.adminSelectedUser.username,
                newusername: this.form.newusername
            }).then(response => {
                return response.json();
                // eslint-disable-next-line no-unused-vars
            }).then(res => {
                this.$bvToast.toast('Username Changed', {
                    title: 'Username Change',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminUserList');
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                this.$bvToast.toast('Error Changing Username', {
                    title: 'Username Change',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        changeNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
