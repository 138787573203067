<template>
    <div>
        <b-tabs content-class="mt-3">
            <b-tab title="Customers" active>
                <div @contextmenu.prevent.stop="handleUserClick($event)" class="item-wrapper">
                    <customer-list></customer-list>
                </div>
            </b-tab>
            <b-tab title="Associates">
                <div @contextmenu.prevent.stop="handleUserClick($event)" class="item-wrapper">
                    <associate-list></associate-list>
                </div>
            </b-tab>
            <b-tab title="Employees">
                <div @contextmenu.prevent.stop="handleUserClick($event)" class="item-wrapper">
                    <employee-list></employee-list>
                </div>
            </b-tab>
        </b-tabs>
        <hr>
        <h4 class="text-center">Devices</h4>
        <hr>
        <div @contextmenu.prevent.stop="handleDevClick($event)" class="item-wrapper">
            <device-list></device-list>
        </div>
        <div>
            <vue-simple-context-menu
                elementId="myUniqueId1"
                :options="ctxUserOptions"
                ref="vueSimpleContextMenu1"
                @option-clicked="contextUserSelect"
            >
            </vue-simple-context-menu>
        </div>
        <div>
            <vue-simple-context-menu
                elementId="myUniqueId2"
                :options="ctxDevOptions"
                ref="vueSimpleContextMenu2"
                @option-clicked="contextDevSelect"
            >
            </vue-simple-context-menu>
        </div>
        <role-dialog :id="userMenuOptions[0].value"></role-dialog>
        <reg-dialog :id="userMenuOptions[1].value"></reg-dialog>
        <status-dialog :id="userMenuOptions[2].value"></status-dialog>
        <set-admin-pin id="setAdminPin"></set-admin-pin>
        <change-comp-dialog :id="userMenuOptions[3].value"></change-comp-dialog>
        <change-email-dialog :id="userMenuOptions[4].value"></change-email-dialog>
        <change-name-dialog :id="userMenuOptions[5].value"></change-name-dialog>
        <change-username-dialog :id="userMenuOptions[6].value"></change-username-dialog>
        <reset-pass-dialog :id="userMenuOptions[8].value"></reset-pass-dialog>
        <change-pass-dialog :id="userMenuOptions[9].value"></change-pass-dialog>
        <delete-dialog id="delUser"></delete-dialog>
        <delete-dev-dialog id="Dev-Delete"></delete-dev-dialog>
        <rem-dev-user id="Rem-User-Dev"></rem-dev-user>
        <b-modal ref="confirmadd-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Add Device To User: {{curUser}} ?</h5>
            </div>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="success" block @click="addUserYes">Yes</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="addUserNo">No</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import roles from '@/roles';
import CustomerList from "@/components/AdminModules/Users/CustomerList";
import AssociateList from "@/components/AdminModules/Users/AssociateList";
import EmployeeList from "@/components/AdminModules/Users/EmployeeList";
import DeviceList from "@/components/AdminModules/Devices/DeviceList";
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import RoleDialog from "@/components/AdminModules/Users/RoleDialog";
import RegDialog from "@/components/AdminModules/Users/RegDialog";
import StatusDialog from "@/components/AdminModules/Users/StatusDialog";
import SetAdminPin from "@/components/AdminModules/Users/SetAdminPin";
import DeleteDialog from "@/components/AdminModules/Users/DeleteDialog";
import DeleteDevDialog from "@/components/AdminModules/Devices/DeleteDevDialog";
import RemDevUser from "@/components/AdminModules/Users/RemDevUser";
import ResetPassDialog from "@/components/AdminModules/Users/ResetPassDialog";
import ChangePassDialog from "@/components/AdminModules/Users/ChangePassDialog";
import ChangeCompDialog from "@/components/AdminModules/Users/ChangeCompDialog";
import ChangeEmailDialog from "@/components/AdminModules/Users/ChangeEmailDialog";
import ChangeNameDialog from "@/components/AdminModules/Users/ChangeNameDialog";
import ChangeUsernameDialog from "@/components/AdminModules/Users/ChangeUsernameDialog";

import { EventBus } from '@/plugins/event-bus';

export default {
    name: "AdminControlPanel",
    data() {
        return {
            userMenuOptions: [
                {value: 'role', name: 'Change Role'},
                {value: 'reg', name: 'Change Registration'},
                {value: 'status', name: 'Change Status'},
                {value: 'company', name: 'Change Company'},
                {value: 'email', name: 'Change Email'},
                {value: 'name', name: 'Change Name'},
                {value: 'userName', name: 'Change Username'},
                {value: 'div1', type: 'divider'},
                {value: 'resetPass', name: 'Reset User Password'},
                {value: 'changePass', name: 'Change User Password'},
                {value: 'div5', type: 'divider'},
                {value: 'setAdminPin', name: 'Set User Device PIN'}
            ],
            devMenuOptions: [
                {value: 'config', name: 'Configure New Device'},
                {value: 'div1', type: 'divider'},
                // {value: 'assignUser', name: 'Assign to User'},
                {value: 'remUser', name: 'Remove from User'},
                {value: 'repUser', name: 'Replace for User'},
                {value: 'div2', type: 'divider'},
                {value: 'resRePro', name: 'Restore - Re-Provision'},
                {value: 'resData', name: 'Restore - Data Only'},
                {value: 'div3', type: 'divider'},
                {value: 'editConfig', name: 'Edit System Config'},
                {value: 'editLoc', name: 'Edit Location'},
                {value: 'editUsers', name: 'Edit Device Users'},
                {value: 'div3b', type: 'divider'},
                {value: 'adminDash', name: 'Device Dashboard'},
                {value: 'div3c', type: 'divider'},
                {value: 'editSch', name: 'Edit Spray Schedule'},
                {value: 'editSus', name: 'Edit Suspend Schedule'},
                {value: 'viewAct', name: 'View Activity History'},
                {value: 'div4', type: 'divider'},
                {value: 'debug', name: 'Debug'}
            ]
        }
    },
    components: {
        CustomerList,
        AssociateList,
        EmployeeList,
        DeviceList,
        VueSimpleContextMenu,
        RoleDialog,
        RegDialog,
        StatusDialog,
        SetAdminPin,
        DeleteDialog,
        DeleteDevDialog,
        RemDevUser,
        ResetPassDialog,
        ChangePassDialog,
        ChangeCompDialog,
        ChangeEmailDialog,
        ChangeNameDialog,
        ChangeUsernameDialog
    },
    methods: {
        contextUserSelect(item) {
            EventBus.$emit(item.option.value);
        },
        handleUserClick (event) {
            this.$refs.vueSimpleContextMenu1.showMenu(event);
        },
        registeredOnly() {
            this.$bvToast.toast('Device Must Be Registered First', {
                title: 'Device',
                variant: 'danger',
                solid: true
            });
        },
        contextDevSelect(item) {
            //console.log(item.option.name);
            if (item.option.value === 'debug') { // Debug 13
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                this.$router.push('/admin/dev/debug/' + this.$store.getters.adminSelectedDev.deviceId);
            }
            else if (item.option.value === 'config') { // Config New Device 0
                if (this.$store.getters.adminSelectedDev.state === 0 && this.$store.getters.adminSelectedDev.userId === '')
                    this.$router.push('/admin/dev/new/config/' + this.$store.getters.adminSelectedDev.deviceId);
                else {
                    this.$bvToast.toast('Only New Devices Can Be Configured', {
                        title: 'Configure New Device',
                        variant: 'danger',
                        solid: true
                    });
                }
            }
            else if (item.option.value === 'assignUser') { // Assign User 2
                if (this.$store.getters.adminSelectedUser === null) {
                    this.$bvToast.toast('Need to Select a User First to Assign the Device', {
                        title: 'Assign User',
                        variant: 'danger',
                        solid: true
                    });
                }
                else if (this.$store.getters.adminSelectedDev.userId.length !== 0) {
                    this.$bvToast.toast('Cannot Assign a Device That is Already Assigned', {
                        title: 'Assign User',
                        variant: 'danger',
                        solid: true
                    });
                }
                else {
                    this.$refs['confirmadd-modal'].show();
                }
            }
            else if (item.option.value === 'remUser') { // Remove User From Device 3
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                if (this.$store.getters.adminSelectedDev.userId.length === 0) {
                    this.$bvToast.toast('Can Only Remove User From Device With Assigned User', {
                        title: 'Remove User From Device',
                        variant: 'danger',
                        solid: true
                    });
                } else {
                    //console.log("Remove User");
                    EventBus.$emit("Rem-User-Dev");
                }
            }
            else if (item.option.value === 'repUser') { // Replace User Device 4
                this.$router.push("/admin/replacedevice");
            }
            else if (item.option.value === 'resRePro') { // Restore / Re-Provision 6
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                if (this.$store.getters.adminSelectedDev.registered) {
                    this.$store.dispatch('restoreDeviceSettings', {
                        deviceId: this.$store.getters.adminSelectedDev.deviceId,
                        mode: 0
                    }).then(() => {
                        this.$bvToast.toast('Restore / Re-Sync Set For Device', {
                            title: 'Restore Device',
                            variant: 'success',
                            solid: true
                        });
                    }).catch(() => {
                        this.$bvToast.toast('Error Setting Restore / Re-Sync', {
                            title: 'Restore Device',
                            variant: 'danger',
                            solid: true
                        });
                    });
                }
                else {
                    this.$bvToast.toast('Cannot Restore / Re-Sync an Unregistered Device', {
                        title: 'Restore Device',
                        variant: 'danger',
                        solid: true
                    });
                }
            }
            else if (item.option.value === 'resData') { // Restore Data Only 7
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                if (this.$store.getters.adminSelectedDev.registered) {
                    this.$store.dispatch('restoreDeviceSettings', {
                        deviceId: this.$store.getters.adminSelectedDev.deviceId,
                        mode: 1
                    }).then(() => {
                        this.$bvToast.toast('Restore / Re-Sync Set For Device', {
                            title: 'Restore Device',
                            variant: 'success',
                            solid: true
                        });
                    }).catch(() => {
                        this.$bvToast.toast('Error Setting Restore / Re-Sync', {
                            title: 'Restore Device',
                            variant: 'danger',
                            solid: true
                        });
                    });
                }
                else {
                    this.$bvToast.toast('Cannot Restore / Re-Sync an Unregistered Device', {
                        title: 'Restore Device',
                        variant: 'danger',
                        solid: true
                    });
                }
            }
            else if (item.option.value === 'editConfig') { // Edit Config
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                this.$router.push('/admin/editor/config/' + this.$store.getters.adminSelectedDev.deviceId);
            }
            else if (item.option.value === 'editLoc') { // Edit Location
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                this.$router.push('/admin/editor/location/' + this.$store.getters.adminSelectedDev.deviceId);
            }
            else if (item.option.value === 'editUsers') { // Edit Device User
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
              this.$router.push('/admin/editor/devusers/' + this.$store.getters.adminSelectedDev.deviceId);
            }
            else if (item.option.value === 'editSch') { // Edit Device Schedule
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                if (this.$store.getters.adminSelectedDev.deviceType === "InsectControl")
                    this.$router.push('/admin/editor/schedule/' + this.$store.getters.adminSelectedDev.deviceId);
                else if (this.$store.getters.adminSelectedDev.deviceType === "CoolMist")
                    this.$router.push('/admin/editor/schedule/coolmist/' + this.$store.getters.adminSelectedDev.deviceId);
            }
            else if (item.option.value === 'editSus') { // Edit Device Schedule
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                this.$router.push('/admin/editor/suspend/' + this.$store.getters.adminSelectedDev.deviceId);
            }
            else if (item.option.value === 'adminDash') {
                if (!this.$store.getters.adminSelectedDev.registered) {
                    this.registeredOnly();
                    return;
                }
                this.$store.commit('setCurDevType', this.$store.getters.adminSelectedDev.deviceType);
                this.$store.commit('clearDeviceDataOther');
                this.$router.push('/admin/dev/dashboard/' + this.$store.getters.adminSelectedDev.deviceId);
            }
            else if (item.option.value === 'viewAct') { // Edit Device Schedule
                if (!this.$store.getters.adminSelectedDev.registered)
                {
                    this.registeredOnly();
                    return;
                }
                if (this.$store.getters.adminSelectedDev.deviceType === "InsectControl")
                    this.$router.push('/admin/view/activity/' + this.$store.getters.adminSelectedDev.deviceId);
                else if (this.$store.getters.adminSelectedDev.deviceType === "CoolMist")
                    this.$router.push('/admin/view/activity/coolmist/' + this.$store.getters.adminSelectedDev.deviceId);

            }
            else if (item.option.value === 'delDevice') { // Delete Device
                EventBus.$emit('Dev-' + item.option.name);
            }
        },
        handleDevClick (event) {
            this.$refs.vueSimpleContextMenu2.showMenu(event);
        },
        addUserYes() {
            const username = this.$store.getters.adminSelectedUser.username;
            const devId = this.$store.getters.adminSelectedDev.deviceId
            this.$store.dispatch('addDeviceToUser', {id: devId, username: username}).then(() => {
                this.$bvToast.toast('User Added to Device', {
                    title: 'Add Device to User',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminDevList');
            }).catch(() => {
                this.$bvToast.toast('Error Adding Device to User', {
                    title: 'Add Device to User',
                    variant: 'danger',
                    solid: true
                });
            });
            this.$refs['confirmadd-modal'].hide();
        },
        addUserNo() {
            this.$refs['confirmadd-modal'].hide();
        }
    },
    computed: {
        ctxUserOptions() {
            let optionList = this.userMenuOptions.slice();

            if (this.$store.getters.role >= roles.roles.admin) {
                // if (this.$store.getters.adminSelectedUser !== null) {
                //     if (this.$store.getters.adminSelectedUser.role >= roles.roles.technician) {
                //         optionList.push({value: 'div5', type: 'divider'});
                //         optionList.push({value: 'setAdminPin', name: 'Set Device Admin PIN'});
                //     }
                // }

                optionList.push({value: 'div5', type: 'divider'});
                optionList.push({value: 'delUser', name: 'Delete'});
            }
            return optionList;
        },
        ctxDevOptions() {
            let optionList = this.devMenuOptions;
            if (this.$store.getters.role >= roles.roles.admin) {
                optionList.push({value: 'div5', type: 'divider'});
                optionList.push({value: 'delDevice', name: 'Delete'});
            }
            return optionList;
        },
        curUser () {
            if (this.$store.getters.adminSelectedUser !== undefined) {
                if (this.$store.getters.adminSelectedUser !== null && this.$store.getters.adminSelectedUser.username !== undefined)
                    return this.$store.getters.adminSelectedUser.username;
                else
                    return '';
            }
            else
                return '';
        }
    }
}
</script>

<style >
.vue-simple-context-menu {
    background-color: #99caef;
}
.vue-simple-context-menu__divider {
    background-color: #2c3e50;
}
</style>
