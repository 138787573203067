<template>
<div>
    <b-modal
        ref="reg-modal"
        hide-footer
        centered
        title="Change Registration"
    >
        <div class="d-block text-center">
            <h5>Registration For User: {{curUser}}</h5>
        </div>
        <hr>
        <b-container>
            <b-row>
                <b-col cols="5">
                    Change Registration:
                </b-col>
                <b-col>
                    <b-form-select v-model="regSelected" :options="regOptions"></b-form-select>
                </b-col>
            </b-row>
        </b-container>
        <hr>
        <b-container class="text-center">
            <b-row cols="2">
                <b-col>
                    <b-button variant="success" block @click="regOK">OK</b-button>
                </b-col>
                <b-col>
                    <b-button variant="warning" block @click="regCancel">Cancel</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "RegDialog",
    props: ['id'],
    data() {
        return {
            regSelected: 0,
            regOptions: [
                {value: 0, text: "Not Registered"},
                {value: 1, text: "Needs to Verify Email"},
                {value: 2, text: "Needs to Change Password"},
                {value: 4, text: "Registered"},
                {value: 5, text: "Reset Password Request"}
            ]
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            //console.log(this.$store.getters.adminSelectedUser);
            this.regSelected = this.$store.getters.adminSelectedUser.reg;
            this.$refs['reg-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods: {
        regOK() {
            this.$refs['reg-modal'].hide();

            this.$store.dispatch('changeUserReg', {
                username: this.$store.getters.adminSelectedUser.username,
                reg: this.regSelected
            }).then(() => {
                this.$bvToast.toast('User Registration Has Been Changed', {
                    title: 'User Registration',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminUserList');
            }).catch(() => {
                this.$bvToast.toast('Error Changing User Registration', {
                    title: 'User Registration',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        regCancel() {
            this.$refs['reg-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
