<template>
<div>
    <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
        <div class="d-block text-center">
            <h5>Delete User: {{curUser}} ?</h5>
        </div>
        <hr>
        <div class="text-center">
            <b-checkbox v-model="checkOpt">Delete User's Device(s)</b-checkbox>
        </div>
        <hr>
        <b-container class="text-center">
            <b-row cols="2">
                <b-col>
                    <b-button variant="danger" block @click="deleteYes">Yes (No Undo)</b-button>
                </b-col>
                <b-col>
                    <b-button variant="primary" block @click="deleteNo">No</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</div>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "DeleteDialog",
    props: ['id'],
    data() {
        return {
            checkOpt: true
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.roleSelected = this.$store.getters.adminSelectedUser.role;
            this.$refs['confirm-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods:{
        deleteYes() {
            this.$refs['confirm-modal'].hide();

            this.$store.dispatch('deleteUser', {
                username: this.$store.getters.adminSelectedUser.username,
                devices: this.$store.getters.adminSelectedUser.devices,
                devDel: this.checkOpt
            }).then(() => {
                this.$bvToast.toast('User Has Been Deleted', {
                    title: 'User Delete',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminUserList');
            }).catch(() => {
                this.$bvToast.toast('Error Deleting User', {
                    title: 'User Delete',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        deleteNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
