<template>
    <div>
        <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Change Password: {{curUser}} ?</h5>
            </div>
            <hr>
            <b-form-group>
                <label class="m-25" for="change-pass">New Password:</label>
                <b-form-input
                    class="m-75"
                    id="change-pass"
                    type="text"
                    v-model="form.pass"
                    required
                ></b-form-input>
            </b-form-group>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="changeYes">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="changeNo">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "ChangePassDialog",
    props: ['id'],
    data() {
        return {
            form: {
                name: null,
                pass: null
            }
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.form.pass = '';
            this.$refs['confirm-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods:{
        changeYes() {
            this.$refs['confirm-modal'].hide();

            this.$http.patch('users/changePass', {
                username: this.$store.getters.adminSelectedUser.username,
                newPass: this.form.pass
            }).then(response => {
                return response.json();
                // eslint-disable-next-line no-unused-vars
            }).then(res => {
                this.$bvToast.toast('User Password Changed', {
                    title: 'Password Change',
                    variant: 'success',
                    solid: true
                });
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                this.$bvToast.toast('Error Changing Password', {
                    title: 'Password Change',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        changeNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
