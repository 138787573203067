<template>
    <div>
        <b-modal
            ref="comp-modal"
            hide-footer
            centered
            title="Change Company"
        >
            <div class="d-block text-center">
                <h5>Company For User: {{curUser}}</h5>
            </div>
            <hr>
            <b-container>
                <b-row>
                    <b-col cols="4">
                        Change Company:
                    </b-col>
                    <b-col>
                        <b-form-select v-model="compSelected" :options="compOptions"></b-form-select>
                    </b-col>
                </b-row>
            </b-container>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="success" block @click="compOK">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="warning" block @click="compCancel">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "ChangeCompDialog",
    props: ['id'],
    data() {
        return {
            compSelected: 0,
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.compSelected = this.$store.getters.adminSelectedUser.company;
            this.$refs['comp-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods: {
        compOK() {
            this.$refs['comp-modal'].hide();

            this.$store.dispatch('changeUserCompany', {
                username: this.$store.getters.adminSelectedUser.username,
                company: this.compSelected
            }).then(() => {
                this.$bvToast.toast('User Company Has Been Changed', {
                    title: 'User Company',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminUserList');
            }).catch(() => {
                this.$bvToast.toast('Error Changing User Company', {
                    title: 'User Company',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        compCancel() {
            this.$refs['comp-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        },
        compOptions() {
            const cList = this.$store.getters.compList;
            let cNameList = [];
            if (cList !== null) {
                for (let i = 0; i < cList.length; i++) {
                    let comp = {
                        text: cList[i].name,
                        value: i
                    };
                    cNameList.push(comp);
                }
            }
            return cNameList;
        }
    }
}
</script>

<style scoped>

</style>
