<template>
<div>
    <b-modal
        ref="role-modal"
        hide-footer
        centered
        title="Change Role"
    >
        <div class="d-block text-center">
            <h5>Role For User: {{curUser}}</h5>
        </div>
        <hr>
        <b-container>
            <b-row>
                <b-col cols="4">
                    Change Role:
                </b-col>
                <b-col>
                    <b-form-select v-model="roleSelected" :options="roleOptions"></b-form-select>
                </b-col>
            </b-row>
        </b-container>
        <hr>
        <b-container class="text-center">
            <b-row cols="2">
                <b-col>
                    <b-button variant="success" block @click="roleOK">OK</b-button>
                </b-col>
                <b-col>
                    <b-button variant="warning" block @click="roleCancel">Cancel</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "RoleDialog",
    props: ['id'],
    data() {
        return {
            roleSelected: 0,
            roleOptions: [
                {value: 0, text: "Guest User"},
                {value: 1, text: "User"},
                {value: 2, text: "Tech"},
                {value: 3, text: "Manager"},
                {value: 4, text: "Admin"},
                {value: 5, text: "Super"}
            ]
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.roleSelected = this.$store.getters.adminSelectedUser.role;
            this.$refs['role-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods: {
        roleOK() {
            this.$refs['role-modal'].hide();

            this.$store.dispatch('changeUserRole', {
                username: this.$store.getters.adminSelectedUser.username,
                role: this.roleSelected
            }).then(() => {
                this.$bvToast.toast('User Role Has Been Changed', {
                    title: 'User Role',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminUserList');
            }).catch(() => {
                this.$bvToast.toast('Error Changing User Role', {
                    title: 'User Role',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        roleCancel() {
            this.$refs['role-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
