<template>
    <div>
        <b-container>
            <b-row cols="4" align-v="center">
                <b-col>
                    Per Page: <b-select
                    style="width: 6rem"
                    :options="perPageOption"
                    v-model="perPage"
                    @change="perPageChange"
                ></b-select>
                </b-col>
                <b-col>
                    <b-button variant="primary" @click="refreshDeviceList">Refresh List</b-button>
                </b-col>
                <b-col>
                    <b-checkbox @change="checkFilter" v-model="chFilter">Hide Deactivated</b-checkbox>
                </b-col>
                <b-col>
                    <b-form-group v-if="showFilterInput"
                        label="Filter"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
        <p></p>
        <div class="text-center">
            <b-table
                striped
                bordered
                small
                hover
                head-variant="light"
                selectable
                @row-selected="onRowSelected"
                @row-contextmenu="rightClicked"
                select-mode="single"
                responsive="sm"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="items"
                :busy="isBusy"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                style="overflow-y: hidden"
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #cell(actLast)="data">
                    <span v-html="data.value"></span>
                </template>
                <template #cell(lastPing)="data">
                    <span v-html="data.value"></span>
                </template>
            </b-table>
            <div>
                <b-container class="border border-info">
                    <b-row cols="4" align-v="center" align-h="center">
                        <b-col cols="3">
                            <b-card-text class="mt-1 mb-1">Currently Selected Device:</b-card-text>
                        </b-col>
                        <b-col>
                            <b-form-input class="text-center mt-1 mb-1" v-model="selected.deviceId" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-container>
                <p></p>
            </div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
            ></b-pagination>
            <hr>
        </div>
    </div>
</template>

<script>
import roles from '@/roles';
import eTypes from "@/enumTypes";
export default {
    name: "DeviceList",
    components: {
    },
    data() {
        return {
            fields: [
                {key: 'deviceId', label: 'ID', sortable: true},
                {key: 'deviceType', label: 'Type', sortable: true },
                {key: 'user', label: 'User', sortable: true},
                {key: 'sysName', label: 'System Name', sortable: true},
                {key: 'state', label: 'State', formatter: this.stateFormat, sortable: true },
                {key: 'sysStatus', label: 'Operation', formatter: this.opFormat, sortable: true },
                {key: 'sch', label: 'Next Spray', formatter: this.nextSprayFormat},
                {key: 'actLast', label: 'Recent Activity', formatter: this.actFormat},
                {key: 'daysUntilEmpty', label: 'Days Until Empty', formatter: this.daysLeftFormat, sortable: true},
                {key: 'lastActTime', label: 'Last Spray', formatter: this.lastSprayFormat, sortable: true},
                {key: 'lastRefill', label: 'Last Refill', formatter: this.lastRefillFormat, sortable: true},
                {key: 'lastPing', label: 'Status', formatter: this.statusFormat, sortable: true}
            ],
            perPageOption: [5, 10, 25, 50, 100],
            stateOptions: [
                {value: 0, text: 'New Device'},
                {value: 1, text: 'Setup Mode'},
                {value: 2, text: 'One Time Auth'},
                {value: 3, text: 'Not Used'},
                {value: 4, text: 'Assigned'},
            ],
            opOption: [
                'Deactivated',
                'Suspended',
                'Activated'
            ],
            sortBy: 'deviceId',
            sortDesc: false,
            chFilter: false,
            selected: [{deviceId: null, userId: null}],
            selectedUser: null,
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
            isBusy: true,
            checkOpt: true,
            showFilterInput: true,
            filter: null,
            filterOn: [
                'deviceId',
                'user',
                'sysName'
            ]
        }
    },
    created() {
        this.isBusy = true;
        this.perPage = this.$store.getters.getAdminDevPerPage;
        this.$store.dispatch('getAdminDevList').then(() => {
            this.totalRows = this.$store.getters.adminDevList.length;
            this.isBusy = false;
        });
        if (this.$store.getters.adminUserList.length === 0) {
            this.$store.dispatch('getAdminUserList');
        }
    },
    methods: {
        perPageChange(value) {
            this.$store.dispatch('updateAdminDevPerPage', value);
        },
        onRowSelected(items) {
            this.selected = items[0];
            this.$store.commit('storeAdminSelectedDevice', this.selected);
        },
        rightClicked (item) {
            this.selected = item;
            this.$store.commit('storeAdminSelectedDevice', this.selected);
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        userIdToCompany(value) {
            const userList = this.$store.getters.adminUserList;
            for (let i = 0; i < userList.length; i++) {
                if (userList[i].id === value)
                    return userList[i].company;
            }
        },
        ipFormat(value) {
            if (value.search('f:') > 0) {
                return value.split('f:')[1];
            }
            else {
                return value;
            }
        },
        checkFilter() {
            //console.log('check filter');
          if (this.chFilter) {
              this.showFilterInput = false;
              this.filterOn = [
                  'sysStatus'
              ]
              this.filter = '2';
          }
          else {
              this.showFilterInput = true;
              this.filterOn = [
                  'deviceId',
                  'user',
                  'sysName'
              ]
              this.filter = null;
          }
        },
        lastSprayFormat(value) {
            if (value === -1) {
                return "---";
            }
            else {
                return this.moment(value * 1000).format('MM/DD/YYYY h:mm A');
            }
        },
        stateFormat(value) {
            return this.stateOptions[value].text;
        },
        opFormat(value) {
            return this.opOption[value];
        },
        statusFormat(value) {
            if (value === -1)
                return "---";
            if (value < 900)
                return "<div style=\"color:green;\">Online</div>";
            else
                return "<div style=\"color:red;font-weight: bold;\">Offline</div>";
        },
        lastRefillFormat(value) {
            if (value.timestamp !== -1) {
                return this.moment(value.timestamp * 1000).format('MM/DD/YYYY h:mm A');
            }
            else {
                return "No Refill";
            }
        },
        actFormat(value) {
            if (value.length === 0)
                return "None"
            else {
                let actStr = "";
                for (let i = 0; i < value.length; i++) {
                    if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LOW)
                        actStr += "<div style=\"color:yellow;\">Low</div>";
                    else if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_REFILL)
                        actStr += "<div style=\"color:blue;\">Refill</div>";
                    else if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NORMAL)
                        actStr += "<div style=\"color:green;\">Normal</div>";
                    else if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_CLOG)
                        actStr += "<div style=\"color:orange;\">Clog</div>";
                    else if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_SKIPPED)
                        actStr += "<div style=\"color:purple;\">Skipped</div>";
                    else if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LEAK)
                        actStr += "<div style=\"color:red;\">Leak</div>";
                    else if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_TEST)
                        actStr += "<div style=\"color:yellow;\">Test</div>";
                    else if (value[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NO_FLOW)
                        actStr += "<div style=\"color:orange;\">No Flow</div>";
                }
                return actStr;
            }
        },
        getLocalOffset(timestamp, altOffset) {
          const geoData = {};
          let dt = new Date();
          geoData.gmtOffset = -(dt.getTimezoneOffset() / 60);
          let totalOffset = geoData.gmtOffset;
          if (altOffset !== 0) {
            if (altOffset !== geoData.gmtOffset) {
              totalOffset = geoData.gmtOffset - (geoData.gmtOffset - altOffset);
            }
          }
          let mn = this.moment(timestamp * 1000).utcOffset(totalOffset * 60);
          let offsetTime = mn.valueOf();
          return offsetTime / 1000;
        },
        calcNext(sched, sus, geoData) {
            let dt = new Date();
            let localOffset = -(dt.getTimezoneOffset() / 60);
            let lookAheadTimestamp = Math.round(Date.now() / 1000);
            if (localOffset !== geoData.gmtOff) {
                let gmtDiff = localOffset - geoData.gmtOff;
                if (gmtDiff > 0)
                    lookAheadTimestamp -= ((localOffset - geoData.gmtOff) * 3600);
                else
                    lookAheadTimestamp += ((localOffset - geoData.gmtOff) * 3600);
            }
            const notFound = true;
            let iterCount = 0;
            while (notFound) {
                lookAheadTimestamp += 60;
                let start = 0;
                let end = sched.length;
                let schTimestamp = 0;

                for (let i = start; i < end; i++) {
                    if (sched[i].stFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                        schTimestamp = sched[i].time;
                    } else if (sched[i].stFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE) {
                        schTimestamp = geoData.sunrise;
                        sched[i].gmtOff = geoData.gmtOff;
                    } else if (sched[i].stFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                        schTimestamp = geoData.sunset;
                        sched[i].gmtOff = geoData.gmtOff;
                    } else if (sched[i].stFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CONTINUOUS) {
                        schTimestamp = sched[i].time;
                    } else if (sched[i].stFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CYCLE) {
                        schTimestamp = sched[i].time;
                    }

                    //console.log(schTimestamp);
                    if (sched[i].stOff !== 0) {
                        schTimestamp += sched[i].stOff * 60;
                    }
                    const offsetTime = this.getLocalOffset(schTimestamp, sched[i].gmtOff);

                    let weekDayMatch = false;
                    let weekdayNum = this.moment(lookAheadTimestamp * 1000).day();
                    if (weekdayNum === 0 && (sched[i].days & eTypes.enums.Days.SUNDAY) === eTypes.enums.Days.SUNDAY)
                        weekDayMatch = true;
                    if (weekdayNum === 1 && (sched[i].days & eTypes.enums.Days.MONDAY) === eTypes.enums.Days.MONDAY)
                        weekDayMatch = true;
                    if (weekdayNum === 2 && (sched[i].days & eTypes.enums.Days.TUESDAY) === eTypes.enums.Days.TUESDAY)
                        weekDayMatch = true;
                    if (weekdayNum === 3 && (sched[i].days & eTypes.enums.Days.WEDNESDAY) === eTypes.enums.Days.WEDNESDAY)
                        weekDayMatch = true;
                    if (weekdayNum === 4 && (sched[i].days & eTypes.enums.Days.THURSDAY) === eTypes.enums.Days.THURSDAY)
                        weekDayMatch = true;
                    if (weekdayNum === 5 && (sched[i].days & eTypes.enums.Days.FRIDAY) === eTypes.enums.Days.FRIDAY)
                        weekDayMatch = true;
                    if (weekdayNum === 6 && (sched[i].days & eTypes.enums.Days.SATURDAY) === eTypes.enums.Days.SATURDAY)
                        weekDayMatch = true;

                    if (weekDayMatch) {
                        let schHour = this.moment(offsetTime * 1000).hour();
                        let schMin = this.moment(offsetTime * 1000).minute();
                        let laHour = this.moment(lookAheadTimestamp * 1000).hour();
                        let laMin = this.moment(lookAheadTimestamp * 1000).minute();
                        if (schHour === laHour && schMin === laMin) {
                            // Check Suspension list
                            let isSuspended = false;
                            for (let j = 0; j < sus.length; j++) {
                                if (lookAheadTimestamp >= sus[j].sTime && lookAheadTimestamp <= sus[j].eTime) {
                                    isSuspended = true;
                                }
                            }

                            if (!isSuspended) {
                                if (this.moment(lookAheadTimestamp * 1000) >= this.moment())
                                    return lookAheadTimestamp;
                            }
                        }
                    }
                }
                if (iterCount > 9999) {
                    return -1;
                }
                iterCount++;
            }
        },
        nextSprayFormat(value) {
            if (value.sch !== undefined) {
                if (value.sch.length === 0)
                    return "Nothing Scheduled";
                else {
                    let nextTime = this.calcNext(value.sch, value.sus, value.geo);
                    if (nextTime !== -1)
                        return this.moment(nextTime * 1000).format("dddd, MM/DD/YYYY h:mm A");
                    else {
                        return "Nothing"
                    }
                }
            }
            else {
                return "Nothing Scheduled";
            }
        },
        daysLeftFormat(value) {
            if (value !== -1)
                return value;
            else
                return "---"
        },
        refreshDeviceList() {
            this.isBusy = true;
            this.$store.dispatch('getAdminDevList').then(() => {
                this.totalRows = this.$store.getters.adminDevList.length;
                this.isBusy = false;
            });
        }
    },
    computed: {
        items() {
            const dList = this.$store.getters.adminDevList;
            if (this.$store.getters.role !== roles.roles.superUser) {
                let filtDevList = [];
                for (let j = 0; j < dList.length; j++) {
                    const comp = this.userIdToCompany(dList[j].userId);
                    if (comp === this.$store.getters.company || dList[j].state === 0) {
                        filtDevList.push(dList[j]);
                    }
                }
                return filtDevList;
            }
            else {
                return dList;
            }
        }
    }
}
</script>

<style scoped>

</style>
