<template>
<div>
    <b-modal
        ref="stat-modal"
        hide-footer
        centered
        title="Change Status"
    >
        <div class="d-block text-center">
            <h5>Status For User: {{curUser}}</h5>
        </div>
        <hr>
        <b-container>
            <b-row>
                <b-col cols="5">
                    Change Status:
                </b-col>
                <b-col>
                    <b-form-select v-model="statSelected" :options="statOptions"></b-form-select>
                </b-col>
            </b-row>
        </b-container>
        <hr>
        <b-container class="text-center">
            <b-row cols="2">
                <b-col>
                    <b-button variant="success" block @click="statOK">OK</b-button>
                </b-col>
                <b-col>
                    <b-button variant="warning" block @click="statCancel">Cancel</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "StatusDialog",
    props: ['id'],
    data() {
        return {
            statSelected: 0,
            statOptions: [
                {value: 0, text: "Active"},
                {value: 1, text: "Disabled"},
                {value: 2, text: "Suspended"}
            ]
        }
    },
    mounted() {
        EventBus.$on(this.id, () => {
            this.roleSelected = this.$store.getters.adminSelectedUser.role;
            this.$refs['stat-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off(this.id);
    },
    methods: {
        statOK() {
            this.$refs['stat-modal'].hide();

            this.$store.dispatch('changeUserStat', {
                username: this.$store.getters.adminSelectedUser.username,
                status: this.statSelected
            }).then(() => {
                this.$bvToast.toast('User Status Has Been Changed', {
                    title: 'User Status',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getAdminUserList');
            }).catch(() => {
                this.$bvToast.toast('Error Changing User Status', {
                    title: 'User Status',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        statCancel() {
            this.$refs['stat-modal'].hide();
        }
    },
    computed: {
        curUser () {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username;
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
